// ** React Imports
import React, { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
import { Permissions } from "@src/enums/permissions";
import { Groups } from "@src/enums/groups"
import { Roles } from "@src/enums/roles";

// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"
import PrivateRoute from "@components/routes/PrivateRoute"

// ** Utils
import { isObjEmpty, isUserLoggedIn, getHomeRouteForLoggedInUser } from "@utils"
import { Policies } from "@src/enums/policies";

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "%s - Logicalis"

// ** Default Route
const DefaultRoute = "/home"
const getHomeRoute = () => {    
  return DefaultRoute;
}

const AuthCallback = lazy(() => import("../../pages/Authentication/AuthCallback"));
const Home = lazy(() => import("../../pages/Home"));
const Health = lazy(() => import("../../pages/Health"));
const AxIntelligence = lazy(() => import("../../pages/AxIntelligence"));
const ServiceCatalogue = lazy(() => import("../../pages/ServiceCatalogue"));
const ReleaseNotes = lazy(() => import("../../pages/ReleaseNotes"));
const Roadmap = lazy(() => import("../../pages/Roadmap"));
const KnowledgeBase = lazy(() => import("../../pages/KnowledgeBase"));
const Sales = lazy(() => import("../../pages/DataIntelligence/Sales"));
const Finance = lazy(() => import("../../pages/DataIntelligence/Finance"));
const Operations = lazy(() => import("../../pages/DataIntelligence/Operations"));
const PlatformAdoption = lazy(() => import("../../pages/DataIntelligence/PlatformAdoption"));
const PortfolioAdoption = lazy(() => import("../../pages/DataIntelligence/PortfolioAdoption"));
const AvailableRoles = lazy(() => import("../../pages/AvailableRoles"));
const FilteringExample = lazy(() => import("../../pages/DataIntelligence/FilteringExample"));
const TilesEmbedExample = lazy(() => import("../../pages/DataIntelligence/TilesEmbedExample"));
const Reports = lazy(() => import("../../pages/Reports"));
const DashboardEmbed = lazy(() => import("../../pages/Reports/Dasboards/DashboardEmbed"));
const InternalRedirect = lazy(() => import("../../pages/Reports/Dasboards/InternalRedirect"));
const ServicePortfolio = lazy(() => import("../../pages/ServicePortfolio"));
const ServicesComparison = lazy(() => import("../../pages/ServicePortfolio/ServicesComparison"));
const SolutionComparison = lazy(() => import("../../pages/ServicePortfolio/SolutionComparison"));
const DeliveryCentres = lazy(() => import("../../pages/ServicePortfolio/DeliveryCentres"));
const SolutionDetails  = lazy(() => import("../../pages/ServicePortfolio/SolutionDetails"));
const ServiceDetails = lazy(() => import( "../../pages/ServicePortfolio/ServiceDetails"));
const MenuAdmin = lazy(() => import( "../../pages/MenuAdmin"));
const ReportAdmin = lazy(() => import("../../pages/ReportAdmin"));
const Security = lazy(() => import( "../../pages/Security"));
const Error = lazy(() => import("../../pages/Error/Error"));
const Unauthorised = lazy(() => import("../../pages/Error/Unauthorised"));
const Services = lazy(() => import('../../pages/Services/Services'));
const ServiceDashboard = lazy(() => import("../../pages/Services/Dashboard/ServiceDashboard"));
// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={getHomeRoute()} />,
    meta: {}
  },
  {
    path: "/auth-callback",
    element: <AuthCallback />,
    meta: {
      publicRoute: true,
      layout: "blank" 
    }
  },
  {
    path: "/home",
    element: <Home />,
    meta: {
      policy: Policies.CanViewHome
    }
  },
  {
    path: "/scores",
    element: <Health />,
    meta: {
      policy: Policies.CanViewHealth
    }
  },
  {
    path: "/health",
    element: <Health />,
    meta: {
      policy: Policies.CanViewHealth
    }
  },
  {
    path: "/ax-intelligence",
    element: <AxIntelligence />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/service-catalogue",
    element: <ServiceCatalogue />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },  
  {
    path: "/release-notes",
    element: <ReleaseNotes />,
    meta: {
      policy: Policies.CanViewReleaseNotes
    }
  },
  {
    path: "/reports",
    element: <Reports />,
    meta: {
      policy: Policies.CanViewReports
    }
  },
  {
    path: "/reports/:reportname",
    element: <DashboardEmbed />,
    meta: {
      policy: Policies.CanViewReports
    }
  },  
  {
    path: "/reports/dashboard/:reportname",
    element: <DashboardEmbed />,
    meta: {
      policy: Policies.CanViewReports
    }
  }, 
  {
    path: "/reports/internal/:reportname",
    element: <InternalRedirect />,
    meta: {
      policy: Policies.CanViewReports
    }
  },
  {
    path: "/dashboard/:reportname",
    element: <DashboardEmbed />,
    meta: {
      policy: Policies.CanViewReports
    }
  },
  {
    path: "/administration/reports-services",
    element: <ReportAdmin />,
    meta: {
      policy: Policies.CanManageReportsAndServices
    }
  },
  {
    path: "/roadmap",
    element: <Roadmap />,
    meta: {
      policy: Policies.CanViewRoadmap
    }
  }, 
  {
    path: "/knowledge-base",
    element: <KnowledgeBase />,
    meta: {
      policy: Policies.CanViewKnowledgeBase
    }
  },
  {
    path: "/knowledge-base/*",
    element: <KnowledgeBase />,
    meta: {
      policy: Policies.CanViewKnowledgeBase
    }
  },
  {
    path: "/data-intelligence/sales",
    element: <Sales />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/finance",
    element: <Finance />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/operations/:urlTab",
    element: <Operations />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/platform-adoption",
    element: <PlatformAdoption />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/portfolio-adoption",
    element: <PortfolioAdoption />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/filtering-example",
    element: <FilteringExample />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/data-intelligence/tiles-embed-example",
    element: <TilesEmbedExample />,
    meta: {
      policy: Policies.CanViewAxIntelligence
    }
  },
  {
    path: "/service-portfolio",
    element: <ServicePortfolio />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
  {
    path: "/service-portfolio/solution-details/:id/:name/:source",
    element: <SolutionDetails />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
   {
    path: "/service-portfolio/service-details/:id/:name/:source",
    element: <ServiceDetails />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
  {
    path: "/service-portfolio/delivery-centres",
    element: <DeliveryCentres />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
  {
    path: "/service-portfolio/services-compare/:serviceNameLeft/:serviceNameRight",
    element: <ServicesComparison />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
  {
    path: "/service-portfolio/solution-compare/:solutionFirst/:solutionNameFirst/:solutionSecond/:solutionNameSecond",
    element: <SolutionComparison />,
    meta: {
      policy: Policies.CanViewServicePortfolios
    }
  },
  {
    path: "/administration/menu",
    element: <MenuAdmin />,
    meta: {
      policy: Policies.CanManageMenu
    }
  },  
  {
    path: "/administration/security/:urlTab",
    element: <Security />,
    meta: {
      policy: Policies.CanManageSecurity
    }
  },
  {
    path: "/administration/app-roles",
    element: <AvailableRoles />,
    meta: {
      policy: Policies.CanManageMenu
    }
  },
  {
    path: '/services',
    element: <Services />,
    meta: {
      policy: Policies.CanViewServices
    }
  },
  {
    path: "/services/:reportname",
    element: <ServiceDashboard />,
    meta: {
      policy: Policies.CanViewServices
    }
  },
  {
    path: "/services/:reportname/:companyid/:companyname/:serviceparam",
    element: <ServiceDashboard />,
    meta: {
      policy: Policies.CanViewServices
    }
  },
  {
    path: '/services/:companySysId',
    element: <Services />,
    meta: {
      policy: Policies.CanViewServices
    }
  },
  
  {
    path: "/error",
    element: <Error />,
    meta: { layout: "blank" }
  },
  {
    path: "/unauthorised",
    element: <Unauthorised />,
    meta: { layout: "blank" }
  },
  {
    path: '*',
    element: <BlankLayout />,
    meta: { layout: "blank" },
    children: [{ 
      path: '*', 
      element: <Error />,
      meta: { layout: "blank" }
    }]
  },
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes: any = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes: any[] = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
